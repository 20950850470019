import { FC, useEffect, useState } from 'react';
import { ActivityTagsByDivisionId } from '@/Lib/types/activity-tags';
import {
  GroupedRegionSearchPanel,
  RegionSearchPanel,
} from '@/Lib/types/region';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { DropdownMultivalue } from '@/Components/drop-down/multivalue';
import { MultiValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import Link from 'next/link';
import { getGroupedRegions, renderDestinationDropdownCounts } from '../helpers';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';

const normalizeExperienceData = (
  experienceData: ActivityTagsByDivisionId[]
) => {
  const experienceDropdownItems = experienceData.map(
    (item: ActivityTagsByDivisionId) => ({
      value: item.name,
      title: item.name,
      image: `website/tags/${item.name.toLowerCase()}/banner.webp`,
      data: item.id,
    })
  );
  return experienceDropdownItems;
};

const normalizeDestinationData = (destinations: RegionSearchPanel[]) => {
  const groupedRegions: GroupedRegionSearchPanel[] =
    getGroupedRegions(destinations);

  const destinationDropdownItems = groupedRegions.map(
    (item: GroupedRegionSearchPanel) => ({
      value: item.name,
      title: item.name,
      description: renderDestinationDropdownCounts(item.count),
      image: `new-website/region/${getInternalName(item.name)}.webp`,
      data: item.activityTags,
    })
  );
  return destinationDropdownItems;
};

const filterDestinationsBasedOnExperience = (
  regions: RegionSearchPanel[],
  experiences: TDropDownItem<number>[]
) => {
  let filteredDestinations: RegionSearchPanel[] = [];
  outer: for (let region of regions) {
    for (let activityTag of region.activity_tags!) {
      if (experiences.findIndex((xp) => xp.data === activityTag.id) > -1) {
        filteredDestinations.push(region);
        break outer;
      }
    }
  }
  return filteredDestinations;
};

type props = {
  experiences: ActivityTagsByDivisionId[];
  destinations: RegionSearchPanel[];
};

const normalizeDropdownSelections = (selections: MultiValue<TDropDownItem>) => {
  return selections?.map((o) =>
    o.value.replace(/-/g, ' ').replace('abroad', '').trim().toLowerCase()
  );
};

const StudySearchForm: FC<props> = ({ experiences, destinations }) => {
  const [selectedExperience, setSelectedExperience] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [experienceDropdownItems, setExperienceDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeExperienceData(experiences));
  const [destinationDropdownItems, setDestinationDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeDestinationData(destinations));

  useEffect(() => {
    setExperienceDropdownItems(normalizeExperienceData(experiences));
  }, [experiences]);

  useEffect(() => {
    const filteredDestinations = filterDestinationsBasedOnExperience(
      destinations,
      selectedExperience as TDropDownItem<number>[]
    );

    if (filteredDestinations.length) {
      setDestinationDropdownItems(
        normalizeDestinationData(filteredDestinations)
      );
    } else {
      setDestinationDropdownItems(normalizeDestinationData(destinations));
    }
  }, [selectedExperience, destinations]);

  const { location } = useLocation();

  const tredingClick = () => {
    RudderAnalytics.findMyTripClicked({
      category: 'study',
      acitivity: normalizeDropdownSelections(selectedExperience),
      destination: normalizeDropdownSelections(selectedDestinations),
      country: location.country.name,
    });
  };

  return (
    <>
      <h1 className="text-xl lg:text-4xl font-bold mb-1 md:mb-2.5">
        Study Abroad Experiences
      </h1>
      <p className="mb-5 text-darkgrey lg:text-lg lg:leading-6">
        Learn something new with culturally immersive lessons somewhere cool.
      </p>

      <div className="flex flex-col lg:flex-row gap-2.5">
        <div className="flex flex-col gap-2.5 w-full flex-1 lg:flex-row search-form-dropdown-container">
          <DropdownMultivalue
            id="study-search-form-experience"
            icon="icon-list"
            placeholder="Experience"
            options={experienceDropdownItems}
            dropdownName="search-panel-work-experience"
            selectedOptions={selectedExperience}
            setSelectedOptions={setSelectedExperience}
            hideClearAll
          />
          <DropdownMultivalue
            id="study-search-form-destination"
            icon="icon-location"
            placeholder="Destination"
            options={destinationDropdownItems}
            dropdownName="search-panel-work-destination"
            selectedOptions={selectedDestinations}
            setSelectedOptions={setSelectedDestinations}
            hideClearAll
          />
        </div>
        <Link
          href={{
            pathname: '/search',
            query: {
              destinations: normalizeDropdownSelections(selectedDestinations),
              activities: normalizeDropdownSelections(selectedExperience),
              divisions: 'study',
            },
          }}
        >
          <a
            onClick={() => tredingClick()}
            className="text-xl md:leading-[1.9rem] md:align-middle text-white font-semibold mt-3 md:mt-0 bg-red-900 rounded-md px-14 py-5 lg:py-3.5 hover:bg-redhover"
          >
            Find my trip
          </a>
        </Link>
      </div>
    </>
  );
};

export default StudySearchForm;
