import { GImage } from '../../g-image';
import { mockData as appBannerData } from './data';
import { useMutation } from '@apollo/client';
import { PhoneCode, PhoneCodeData } from '@/Lib/types/phone-code';
import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { TDropDownItem } from '@/Components/drop-down/types';
import { Input } from '@/Components/form-elements/Input/Input';
import { SEND_APP_LINK } from '@/Lib/graphql/queries/send-app-link.query';
import { DropdownSinglevalue } from '@/Components/drop-down/singlevalue';
import { ModalCentered } from '@/Components/modals/modal-centered';

function AppBanner(data: { data: PhoneCodeData }): JSX.Element {
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<SingleValue<TDropDownItem>>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errors, setErrors] = useState<{ country: string; phone: string }>({
    country: '',
    phone: '',
  });
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState('');

  const dropDownCountryCodesData = data.data?.phoneCodes.map(
    (item: PhoneCode) => {
      return {
        name: item.name,
        value: item.dial_code,
        image: item.code.split(',')[0].toLowerCase(),
        title: item.dial_code,
      };
    }
  );

  const [
    sendAppLink,
    {
      loading: sendAppLinkLoading,
      data: sendAppLinkData,
      error: sendAppLinkError,
    },
  ] = useMutation<{
    sendAppLink: {
      code: number;
      success: boolean;
      message: string;
    };
  }>(SEND_APP_LINK);

  function changeHandler(
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    // @ts-ignore
    setPhoneNumber(e.target.value);
  }

  function checkFields(): boolean {
    if (!selectedCountryCode) {
      setErrors({ country: 'Country code cannot be empty!', phone: '' });
      return true;
    }
    if (!phoneNumber) {
      setErrors({ country: '', phone: 'Phone Number cannot be empty!' });
      return true;
    }
    setErrors({ country: '', phone: '' });
    return false;
  }

  function submitHandler() {
    const hasErrors = checkFields();
    if (hasErrors) return;
    // error free now
    sendAppLink({
      variables: {
        number: `${selectedCountryCode?.title}${phoneNumber}`,
        country: selectedCountryCode?.name,
      },
    });
  }

  useEffect(() => {
    if (!sendAppLinkLoading && !sendAppLinkError && sendAppLinkData) {
      setAlert(true);
      setAlertText(sendAppLinkData.sendAppLink.message);

      //   icon: sendAppLinkData.sendAppLink.success ? 'success' : 'error',
      //   text: sendAppLinkData.sendAppLink.message,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAppLinkLoading, sendAppLinkError, sendAppLinkData]);

  return (
    <>
      <ModalCentered
        isModalOpen={alert}
        setIsModalOpen={setAlert || (() => {})}
        hasAlternativeWidth={true}
        hasPreventDefault={false}
        hasMdRoudedBorders
      >
        <div className="w-[31rem]">
          <p>{alertText}</p>
        </div>
      </ModalCentered>
      <div className="relative flex flex-col overflow-hidden lg:overflow-visible border border-light-900 rounded-md mb-16 md:mb-20 lg:py-44 lg:px-24 lg:flex-row lg:border-none">
        <div className="flex h-50 w-full lg:absolute lg:inset-0 lg:h-auto rounded-md overflow-hidden">
          <GImage
            path={appBannerData.image}
            transformation="product-banner-lg"
            alt="people hanging out and using their phones"
            width="100%"
            height="100%"
            hasLoadingBackground
          />
          <div className="hidden lg:block lg:absolute lg:inset-0 bg-pureblack opacity-40"></div>
        </div>
        <div className="flex justify-center items-end w-full z-10 p-5 box-border lg:p-0">
          <div className="flex-1-only">
            <h2 className="text-xl mb-1 font-bold leading-6 text-dark-800 lg:text-light-600 lg:text-3xl lg:leading-8">
              {appBannerData.title}
            </h2>
            <p className="text-base leading-5 text-dark-600 lg:text-light-600 lg:text-lg lg:leading-6 lg:mr-2">
              {appBannerData.description}
            </p>
            <div className="flex mt-5 gap-4 items-center">
              <a
                aria-label="iOS app store"
                className="h-10 flex-1-only flex justify-center items-center  lg:h-10 lg:w-30 lg:flex-none"
                href={appBannerData.iosApp.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GImage
                  path={appBannerData.iosApp.image}
                  classes="object-contain rounded-md"
                  alt="download on the app store logo"
                  height="100%"
                />
              </a>
              <a
                aria-label="google play"
                className="h-10 flex-1-only flex justify-center items-center lg:h-10 lg:w-36 lg:flex-none"
                href={appBannerData.androidApp.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GImage
                  path={appBannerData.androidApp.image}
                  alt="coming soon on google play logo"
                  height="100%"
                  styles={{ width: 'auto' }}
                />
              </a>
            </div>
          </div>
          <div className="hidden lg:block flex-1-only bg-light-600 pt-7 px-7 rounded-md">
            <h3 className="text-xl leading-6 font-semibold text-dark-800">
              {appBannerData.contact.title}
            </h3>
            <p className="text-base leading-5 font-semibold text-dark-600">
              {appBannerData.contact.description}
            </p>
            <div className="flex h-14 items-center mt-3 gap-2.5">
              <div className="w-30">
                <DropdownSinglevalue
                  id="app-banner-country-code"
                  options={dropDownCountryCodesData}
                  placeholder="Code"
                  setOption={setSelectedCountryCode}
                  hasFlagImage
                />
              </div>
              <div className="flex-1-only">
                <Input
                  changeHandler={changeHandler}
                  placeholder="Phone Number"
                  name="phone"
                  value={phoneNumber}
                  doesntHaveError
                />
              </div>
              <div>
                <button
                  className="bg-teal-900 text-light-600 rounded-md px-5 py-3.5 text-lg leading-6 font-semibold"
                  type="button"
                  onClick={submitHandler}
                >
                  Text me
                </button>
              </div>
            </div>
            <p
              className={
                'relative bottom-1 pt-2 pb-1 text-base font-semibold text-red-900' +
                (errors.country || errors.phone ? ' visible' : ' invisible')
              }
            >
              {errors.country || ''}
              {errors.phone || ''}
              {/* 'A' is just to prevent UI shift */}
              {!errors.country && !errors.phone ? 'A' : ''}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export { AppBanner };
