import { BasicTrip } from '@/Lib/types/trip';
import { Trips } from '@/Components/trips';
import Link from 'next/link';
import { useTrendingTrips } from '@/Lib/hooks/useTrendingTrips';
import { getDivisionsByTab } from '@/Lib/helpers/tab-division';
import { getTitleByTab } from '@/Lib/helpers/trending-trips';
import { useDevice } from '@/Lib/hooks/useDevice';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useLocation } from '@/Lib/hooks/useLocation';

type Props = {
  activeTab: string;
  countryName: string;
};

const TendingTrips = ({ activeTab, countryName }: Props) => {
  const { tripsLoading, tripsData } = useTrendingTrips({
    activeTab,
    country: countryName,
  });

  const { isMD } = useDevice();

  const title = getTitleByTab(activeTab);
  const activeDivisions = getDivisionsByTab(activeTab);

  const { location } = useLocation();

  const clickProductCard = (index: number, trip: BasicTrip) => {
    RudderAnalytics.trendingTripsClicked({
      product_id: trip.id,
      product_name: trip.tripName,
      position: index + 1,
      tab: activeTab.toLowerCase(),
      country: location.country.name,
    });
  };

  const tripsLoadedAndExist =
    !tripsLoading && tripsData && tripsData?.items?.length;

  return (
    <>
      <section className="py-4 my-8 md:mx-10">
        {tripsLoadedAndExist ? (
          <>
            <div className="px-0 md:px-0">
              <Trips
                headerText={title}
                trips={
                  isMD
                    ? tripsData?.items.slice(0, 4)
                    : tripsData?.items.slice(0, 5)
                }
                clickProductCard={(index: number, trip: BasicTrip) =>
                  clickProductCard(index, trip)
                }
              />
            </div>
            <div className="mx-5 lg:mx-0 ">
              <Link
                href={{
                  pathname: '/search',
                  query: {
                    divisions: activeDivisions.map((item) =>
                      item.toLocaleLowerCase()
                    ),
                  },
                }}
              >
                <a className="mt-4 font-bold text-left text-teal-900 underline cursor-pointer">
                  See more
                </a>
              </Link>
            </div>
          </>
        ) : tripsLoading ? (
          <div className="px-0 md:px-0">
            <Trips headerText={title} isLoading />
          </div>
        ) : null}
      </section>
    </>
  );
};
export default TendingTrips;
