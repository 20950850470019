import { DropdownMultivalue } from '@/Components/drop-down/multivalue';
import { TDropDownItem } from '@/Components/drop-down/types';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { titleCase } from '@/Lib/helpers/title-case';
import Link from 'next/link';
import { DivisionSearchPanel } from '@/Lib/types/division';
import {
  GroupedRegionSearchPanel,
  RegionSearchPanel,
} from '@/Lib/types/region';
import { FC, useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { renderDestinationDropdownCounts } from '../helpers';
import { getGroupedRegions } from '../helpers';
import { useLocation } from '@/Lib/hooks/useLocation';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';

const normalizeDestinationDropdownItems = (
  destinations: RegionSearchPanel[]
) => {
  const groupedRegions: GroupedRegionSearchPanel[] =
    getGroupedRegions(destinations);
  const destinationDropdownItems = groupedRegions.map(
    (item: GroupedRegionSearchPanel) => ({
      value: getInternalName(item.name),
      title: item.name,
      description: renderDestinationDropdownCounts(item.count),
      image: `new-website/region/${
        getInternalName(item.name) === 'pacific'
          ? 'australia'
          : getInternalName(item.name)
      }.webp`,
    })
  );
  return destinationDropdownItems;
};

const normalizeDivisionDropdownItems = (divisions: DivisionSearchPanel[]) => {
  const allDivisionDropdownItems: TDropDownItem<string[]>[] = divisions.map(
    (item: DivisionSearchPanel) => ({
      value: item.internalName,
      type: item.type,
      title: titleCase(item.secondaryName),
      description: item.summary,
      image: `website/division/${getInternalName(item.type)}/banner.webp`,
      data: item.destinations,
    })
  );

  const divisionDropdownItems = allDivisionDropdownItems.filter(
    (item) => !['study-abroad', 'tour'].includes(item.value)
  );

  return divisionDropdownItems;
};

declare const rudderanalytics: any;

const filterDestinationsBasedOnExperience = (
  destinations: RegionSearchPanel[],
  experiences: TDropDownItem<string[]>[]
): RegionSearchPanel[] => {
  let filteredDestinations: RegionSearchPanel[] = [];
  experiences.forEach((xp) => {
    destinations.forEach((destination) => {
      if (
        destination.division === xp.type &&
        xp.data?.includes(destination.destination)
      ) {
        filteredDestinations.push(destination);
      }
    });
  });
  return filteredDestinations;
};

type props = {
  divisions: DivisionSearchPanel[];
  destinations: RegionSearchPanel[];
};

const WorkSearchForm: FC<props> = ({ divisions, destinations }) => {
  const [selectedDivisions, setSelectedDivisions] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [selectedDestinations, setSelectedDestinations] = useState<
    MultiValue<TDropDownItem>
  >([]);
  const [divisionDropdownItems, setDivisionDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeDivisionDropdownItems(divisions));
  const [destinationDropdownItems, setDestinationDropdownItems] = useState<
    TDropDownItem[]
  >(normalizeDestinationDropdownItems(destinations));

  useEffect(() => {
    setDivisionDropdownItems(normalizeDivisionDropdownItems(divisions));
  }, [divisions]);

  useEffect(() => {
    const filteredDestinations = filterDestinationsBasedOnExperience(
      destinations,
      selectedDivisions as TDropDownItem<string[]>[]
    );

    if (filteredDestinations.length) {
      setDestinationDropdownItems(
        normalizeDestinationDropdownItems(filteredDestinations)
      );
    } else {
      setDestinationDropdownItems(
        normalizeDestinationDropdownItems(destinations)
      );
    }
  }, [selectedDivisions, destinations]);

  const normalizeDropdownSelections = (
    selections: MultiValue<TDropDownItem>
  ) => {
    return selections?.map((o) =>
      o.value.replace(/-/g, ' ').replace('abroad', '').trim()
    );
  };

  const { location } = useLocation();

  const tredingClick = () => {
    RudderAnalytics.findMyTripClicked({
      category: 'work',
      division: normalizeDropdownSelections(
        selectedDivisions.length ? selectedDivisions : divisionDropdownItems
      ),
      destination: normalizeDropdownSelections(selectedDestinations),
      country: location.country.name,
    });
  };

  return (
    <>
      <h2 className="text-xl lg:text-4xl font-bold mb-1 md:mb-2.5">
        Work Abroad Experiences
      </h2>
      <p className="mb-5 text-darkgrey lg:text-lg lg:leading-6">
        The easy way to work & travel around the world, both paid & unpaid.
      </p>

      <div className="flex flex-col lg:flex-row gap-2.5">
        <div className="flex flex-col gap-2.5 w-full flex-1 lg:flex-row search-form-dropdown-container">
          <DropdownMultivalue
            id="work-search-form-experience"
            icon="icon-list"
            placeholder="Experience"
            options={divisionDropdownItems}
            dropdownName="search-panel-work-experience"
            selectedOptions={selectedDivisions}
            setSelectedOptions={setSelectedDivisions}
            hideClearAll
          />
          <DropdownMultivalue
            id="work-search-form-destination"
            icon="icon-location"
            placeholder="Destination"
            options={destinationDropdownItems}
            dropdownName="search-panel-work-destination"
            selectedOptions={selectedDestinations}
            setSelectedOptions={setSelectedDestinations}
            hideClearAll
          />
        </div>
        <Link
          href={{
            pathname: '/search',
            query: {
              destinations: normalizeDropdownSelections(selectedDestinations),
              divisions: normalizeDropdownSelections(
                selectedDivisions.length
                  ? selectedDivisions
                  : divisionDropdownItems
              ),
            },
          }}
        >
          <a
            onClick={() => tredingClick()}
            className="text-xl md:leading-[1.9rem] md:align-middle text-white font-semibold mt-3 md:mt-0 bg-red-900 rounded-md px-14 py-5 lg:py-3.5 hover:bg-redhover"
          >
            Find my trip
          </a>
        </Link>
      </div>
    </>
  );
};

export default WorkSearchForm;
