import SearchTabs from '@/Components/search-panel/search-tabs';

export const getTitleByTab = (activeTab: string) => {
  switch (activeTab) {
    case SearchTabs.WORK:
      return 'Trending trips';
    case SearchTabs.STUDY:
      return 'Top study experiences';
    case SearchTabs.TOUR:
      return 'Top tours';
    default:
      return 'Trending trips';
  }
};
