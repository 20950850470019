import type { GetStaticProps, NextPage } from 'next';

import { ReviewsSection } from '@/Components/home/reviews-section';
import { DivisionCard } from '@/Components/cards/division-card';
import { ProductCardPromoted } from '@/Components/cards/product-card-promoted';
import { GWorldBanner } from '@/Components/home/gworld-banner';
import Blog from '@/Components/blog';
import { InsuranceBanner } from '@/Components/home/insurance-banner';
import { AppBanner } from '@/Components/home/app-banner';
import { ReviewsBanner } from '@/Components/home/reviews-banner';
import SearchPanel from '@/Components/search-panel/search-panel';

import { initializeApollo } from '@/Lib/graphql/apollo-client';
import { NAVIGATION_MENU_QUERY } from '@/Lib/graphql/queries/navigation-menu.query';
import {
  DIVISIONS_QUERY,
  DIVISIONS_SEARCH_PANEL,
} from '@/Lib/graphql/queries/divisions.query';
import {
  Division,
  DivisionSearchPanel,
  DivisionSearchPanelQuery,
} from '@/Lib/types/division';
import {
  REGIONS_QUERY,
  REGIONS_SEARCH_PANEL,
  REGIONS_STUDY_SEARCH_PANEL,
} from '@/Lib/graphql/queries/region.query';
import { POPULAR_DESTINATIONS } from '@/Lib/graphql/queries/popular-destinations';
import { PHONE_CODE_QUERY } from '@/Lib/graphql/queries/phone-code.query';
import {
  TOUR_OPERATORS_AND_DESTINATIONS_SEARCH_PANEL,
  TRIPS,
} from '@/Lib/graphql/queries/trip.query';
import { ACTIVITY_TAGS_BY_DIVISION_ID } from '@/Lib/graphql/queries/activity-tags.query';
import { useEffect, useState } from 'react';
import SearchTabs from '@/Components/search-panel/search-tabs';
import { RecentDiscoveries } from '@/Components/home/recent-discoveries';
import LeadingBrand from '@/Components/home/leading-brand';
import { ConfigQuery } from '@/Lib/graphql/queries/config.query';
import TabDivision from 'data/tab-division';
import TendingTrips from '@/Components/home/trending-trips';
import { useLocation } from '@/Lib/hooks/useLocation';
import { PhoneCodeData } from '@/Lib/types/phone-code';
import PopularDestination from '@/Components/home/popular-destination';
import { GET_BLOGPOST_QUERY } from '@/Lib/graphql/queries/blog.query';
import { BlogData } from '@/Lib/types/blog';
import { navigationDataFn } from '@/Lib/function/navigation';
import Head from 'next/head';
import { getMeta } from '@/Components/meta/meta.query';
import { defaultLocation } from 'data/default-location';
import { RegionSearchPanel, RegionSearchPanelQuery } from '@/Lib/types/region';
import {
  ActivityTagsByDivisionId,
  ActivityTagsByDivisionIdQuery,
} from '@/Lib/types/activity-tags';
import {
  TourOperatorAndDestinationSearchPanel,
  TourOperatorAndDestinationSearchPanelQuery,
} from '@/Lib/types/trip';
import { TPromotedCard } from '@/Components/cards/product-card-promoted/types';
import { getPromotedProductCards } from '@/Lib/graphql/helpers/promoted-product-card';
import { useRouter } from 'next/router';
import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { useAtomValue } from 'jotai';
import { activeTabAtom, promotionsAtom } from '@/State/global/global.store';
import { getHeaderData } from '@/Lib/graphql/helpers/header-data';

type props = {
  initialApolloState: any;
  workExperienceDropDown: DivisionSearchPanel[];
  workDestinationDropDown: RegionSearchPanel[];
  studyExperienceDropDown: ActivityTagsByDivisionId[];
  studyDestinationDropDown: RegionSearchPanel[];
  tourOperatorsAndDestinations: TourOperatorAndDestinationSearchPanel[];
  divisionData: { divisions: Division[] };
  appBannerData: PhoneCodeData;
  dataBlog: BlogData;
  promotedCardData: TPromotedCard[];
};

type divisionInputType = 'WORK' | 'STUDY' | 'TOUR' | 'FLY';

const Home: NextPage<props> = ({
  workExperienceDropDown,
  workDestinationDropDown,
  studyExperienceDropDown,
  studyDestinationDropDown,
  tourOperatorsAndDestinations,
  divisionData,
  appBannerData,
  dataBlog,
  promotedCardData,
}) => {
  const router = useRouter();
  const activeTabGlobalState = useAtomValue(activeTabAtom);
  const { location } = useLocation();
  const countryName = location.country.name;
  const [activeTab, setActiveTab] = useState(activeTabGlobalState);
  const isGApp = useIsGAppUserAgent();

  const ChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  // must exclude some divisions from all our divisions
  const divisions = divisionData?.divisions.filter(
    (d) => !['study-abroad', 'tour'].includes(d.internalName)
  );

  useEffect(() => {
    if (router.route !== '/') return;
    RudderAnalytics.homePageViewed({
      country: location.country.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);

  const onClickExplore = (index: number, division: Division) => {
    RudderAnalytics.exploreByIntrestClicked({
      division_name: division.name,
      position: index + 1,
      country: location.country.name,
    });
  };

  return (
    <div className="mx-auto">
      <Head>
        <link
          rel="preload"
          href="https://res.cloudinary.com/gwatco/image/upload/t_banner-xl,dpr_auto/new-website/static/homepage/banner-test2.webp"
          as="image"
        />
      </Head>
      <main>
        <SearchPanel
          workExperienceDropDown={workExperienceDropDown}
          workDestinationDropDown={workDestinationDropDown}
          studyExperienceDropDown={studyExperienceDropDown}
          studyDestinationDropDown={studyDestinationDropDown}
          tourOperatorsAndDestinations={tourOperatorsAndDestinations}
          change={(tab: any) => ChangeTab(tab)}
        />
        <div className="max-w-6.75xl m-auto">
          <ReviewsSection />
          <RecentDiscoveries />
          {activeTab === SearchTabs.WORK && (
            <section className="py-4 my-8 md:mx-10">
              <h2 className="px-5 mb-5 text-3xl font-bold text-black md:px-0">
                Explore by interest
              </h2>
              <div
                className="max-w-full px-5 overflow-scroll grid grid-cols-8-10rem lg:grid-cols-4 lg:gap-6 hide-webkit-scrollbar lg:overflow-auto md:px-0"
                style={{
                  scrollBehavior: 'smooth',
                }}
              >
                {divisions ? (
                  <>
                    {divisions.map((division, index) => (
                      <DivisionCard
                        key={index}
                        division={division}
                        click={() => onClickExplore(index, division)}
                      />
                    ))}
                  </>
                ) : null}
              </div>
            </section>
          )}
          <TendingTrips countryName={countryName} activeTab={activeTab} />

          {(activeTab === SearchTabs.WORK ||
            activeTab === SearchTabs.STUDY) && (
            <div className="mx-5 my-8 lg:mx-10">
              <ProductCardPromoted
                tab={activeTab}
                promotedCardData={promotedCardData}
              />
            </div>
          )}
        </div>
        {activeTab === SearchTabs.TOUR && <LeadingBrand />}
        <div className="max-w-6.75xl m-auto">
          <PopularDestination activeTab={activeTab as divisionInputType} />
          <div className="mx-5 my-8 lg:mx-10 lg:px-0">
            <GWorldBanner />
          </div>
          <div className="mx-5 my-10 lg:mx-10">
            <InsuranceBanner />
          </div>
          <section className="mb-16 mt-11 md:my-16 md:mx-10">
            <h2 className="px-5 mb-5 text-3xl font-bold text-black md:px-0">
              Get inspired
            </h2>
            <div className="px-5 overflow-scroll grid grid-cols-3-15rem hide-webkit-scrollbar xl:grid-cols-3 gap-5 md:px-0">
              <Blog data={dataBlog} />
            </div>
          </section>
          {!isGApp && (
            <div className="mx-5 my-8 lg:mx-10 lg:px-0">
              <AppBanner data={appBannerData} />
            </div>
          )}
        </div>
        <ReviewsBanner />
      </main>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  // SSG uses default location since we don't want to create all versions statically
  const countryName = defaultLocation.country.name;

  const apolloClient = initializeApollo();

  const meta = await getMeta(apolloClient, '/');

  const { data: workExperienceDropDown } = (await apolloClient.query({
    query: DIVISIONS_SEARCH_PANEL,
  })) as DivisionSearchPanelQuery;

  const { data: workDestinationDropDown } = (await apolloClient.query({
    query: REGIONS_SEARCH_PANEL,
  })) as RegionSearchPanelQuery;

  const { data: studyExperienceDropDown } = (await apolloClient.query({
    query: ACTIVITY_TAGS_BY_DIVISION_ID,
    variables: { divisionId: 8 }, //STUDY
  })) as ActivityTagsByDivisionIdQuery;

  const { data: studyDestinationDropDown } = (await apolloClient.query<any>({
    query: REGIONS_STUDY_SEARCH_PANEL,
  })) as RegionSearchPanelQuery;

  const { data: tourOperatorsAndDestiantions } = (await apolloClient.query({
    query: TOUR_OPERATORS_AND_DESTINATIONS_SEARCH_PANEL,
  })) as TourOperatorAndDestinationSearchPanelQuery;

  const { data: divisionData } = await apolloClient.query<{
    divisions: Division[];
  }>({
    query: DIVISIONS_QUERY,
  });

  const { data: dataBlog } = await apolloClient.query<BlogData>({
    query: GET_BLOGPOST_QUERY,
    variables: { limit: 3 }, //, tags: []
  });

  const { data: appBannerData } = await apolloClient.query<PhoneCodeData>({
    query: PHONE_CODE_QUERY,
  });

  const navigationData = await navigationDataFn(apolloClient);
  const headerData = await getHeaderData(apolloClient);

  const promotedCardData = await getPromotedProductCards(apolloClient);

  await apolloClient.query({ query: ConfigQuery });

  await apolloClient.query({
    query: REGIONS_QUERY,
  });

  await apolloClient.query({
    query: DIVISIONS_QUERY,
  });

  await apolloClient.query({
    query: POPULAR_DESTINATIONS,
    variables: {
      country: countryName,
      divisions: TabDivision.WORK,
    },
  });

  await apolloClient.query({
    query: TRIPS,
    variables: {
      limit: 4,
      order: 'popularity',
      divisions: ['working holiday'],
    },
  });

  await apolloClient.query({
    query: NAVIGATION_MENU_QUERY,
    context: '',
  });

  return {
    props: {
      meta,
      workExperienceDropDown: workExperienceDropDown.divisions,
      workDestinationDropDown: workDestinationDropDown.trips.items,
      studyExperienceDropDown:
        studyExperienceDropDown.activity_tags_by_division_id,
      studyDestinationDropDown: studyDestinationDropDown.trips.items,
      tourOperatorsAndDestinations: tourOperatorsAndDestiantions.trips.items,
      divisionData,
      appBannerData,
      dataBlog,
      navigationData,
      headerData,
      promotedCardData,
    },
    revalidate: 3600,
  };
};

export default Home;
