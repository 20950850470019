import { GImage } from '../../g-image';
import { FC } from 'react';
import { BannerPathFactory } from '@/Lib/helpers/banner-path-factory';
import Link from 'next/link';
import { getInternalName } from '@/Lib/helpers/get-internal-name';

import { PopularDestination } from '@/Lib/types/destination';

type props = {
  destination: PopularDestination;
};

const DestinationCard: FC<props> = ({ destination }) => {
  return (
    <Link href={'/destination/' + getInternalName(destination.destination)}>
      <a className="flex lg:flex-col lg:items-start items-center w-full min-w-13">
        <div className="w-20 h-20 min-w-[5rem] lg:w-full lg:h-80 mr-4">
          <GImage
            path={BannerPathFactory.fromDestination(destination.destination)}
            alt={destination.destination}
            transformation="trip-cards"
            classes="rounded-md object-center"
            width="100%"
            height="100%"
            hasLoadingBackground
          />
        </div>
        <div className="inline-block text-lg lg:text-xl lg:leading-6 lg:mt-2.5 leading-5 font-bold text-dark-800">
          {destination.destination}
        </div>
      </a>
    </Link>
  );
};

export { DestinationCard };
