import { GImage } from '../../g-image';
import Link from 'next/link';

import { gworldBannerData } from './data';

function GWorldBanner(): JSX.Element {
  return (
    <div className="relative flex flex-col lg:flex-row px-5 lg:pr-24 lg:pl-16 pb-7 pt-38vw lg:py-0 lg:h-100 sm:pt-30vw mt-50vw sm:mt-30vw lg:my-24 justify-center items-center bg-darkaqua rounded-md">
      <div className="absolute lg:relative -top-37vw sm:-top-28vw lg:top-0 lg:right-40 xl:right-8 flex-shrink lg:max-w-md lg:flex-1.2">
        <div className="mx-auto w-91vw sm:w-70vw lg:w-110">
          <GImage alt={gworldBannerData.title} path={gworldBannerData.image} />
        </div>
      </div>
      <div className="flex-1 text-light-600 xl:ml-36">
        <h2 className="text-3xl leading-8 font-bold mb-2.5">
          {gworldBannerData.title}
        </h2>
        <p className="text-base leading-5 text-light-700">
          {gworldBannerData.description}
        </p>
        <Link href="/gworld">
          <a className="inline-block mt-8 bg-transparent border border-light-600 rounded-md py-2 px-5 font-semibold">
            Learn more
          </a>
        </Link>
      </div>
    </div>
  );
}

export { GWorldBanner };
