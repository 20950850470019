import { TRENDING_TRIPS } from '@/Lib/graphql/queries/trending-trip.query';
import {
  TrendingTripFallbackResult,
  TrendingTripQueryResult,
} from '@/Lib/types/trending-trip';
import { useQuery } from '@apollo/client';
import { TRIPS } from '@/Lib/graphql/queries/trip.query';
import { getDivisionsByTab } from '../helpers/tab-division';

type Props = {
  activeTab: string;
  country: string;
};

const useTrendingTrips = ({ activeTab, country }: Props) => {
  const activeDivisions = getDivisionsByTab(activeTab);

  const { loading: tripsLoading, data: tripsData } =
    useQuery<TrendingTripQueryResult>(TRENDING_TRIPS, {
      variables: {
        country: country,
        divisions: activeDivisions,
      },
    });

  const { loading: fallbackLoading, data: fallbackData } =
    useQuery<TrendingTripFallbackResult>(TRIPS, {
      variables: {
        limit: 5,
        divisions: activeDivisions,
      },
    });

  return tripsData && tripsData.trendingTrips.items.length >= 4
    ? {
      tripsLoading,
      tripsData: tripsData.trendingTrips,
    }
    : {
      tripsLoading: fallbackLoading,
      tripsData: fallbackData?.trips,
    };
};

export { useTrendingTrips };
