import { DestinationCard } from '@/Components/cards/destination-card';
import SearchTabs from '@/Components/search-panel/search-tabs';
import { POPULAR_DESTINATIONS } from '@/Lib/graphql/queries/popular-destinations';
import { useLocation } from '@/Lib/hooks/useLocation';
import {
  PopularDestination as TPopularDestination,
  PopularDestinationData,
} from '@/Lib/types/destination';
import { useQuery } from '@apollo/client';
import TabDivision from 'data/tab-division';
import { useEffect, useState } from 'react';

type Props = {
  activeTab: divisionInputType;
};

type divisionInputType = 'WORK' | 'STUDY' | 'TOUR' | 'FLY';

const SelectDivision = (type: divisionInputType) => {
  return (
    (type === SearchTabs.WORK && TabDivision.WORK) ||
    (type === SearchTabs.STUDY && TabDivision.STUDY) ||
    (type === SearchTabs.TOUR && TabDivision.TOUR)
  );
};

const PopularDestination = ({ activeTab }: Props) => {
  const [activeTabDivisions, setActiveTabDivisions] = useState(SearchTabs.WORK);
  const { location } = useLocation();
  const { loading: popularDestinationsLoading, data: popularDestinationsData } =
    useQuery<PopularDestinationData>(POPULAR_DESTINATIONS, {
      variables: {
        country: location.country.name,
        divisions: SelectDivision(activeTabDivisions as divisionInputType),
      },
    });

  useEffect(() => {
    setActiveTabDivisions(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (
      popularDestinationsData &&
      popularDestinationsData?.PopularDestinations.length < 5
    ) {
      setActiveTabDivisions(SearchTabs.WORK);
    }
  }, [popularDestinationsData]);

  return (
    <section className="py-4 my-12 md:mx-10">
      <h2 className="text-3xl font-bold text-black mb-5 px-5 md:px-0">
        Popular destinations
      </h2>
      <div className="grid overflow-x-scroll overflow-y-hidden hide-webkit-scrollbar gap-4 lg:gap-8 grid-cols-3-1fr lg:grid-cols-5-1fr px-5 md:px-0">
        {!popularDestinationsLoading && popularDestinationsData
          ? popularDestinationsData?.PopularDestinations?.map(
              (destination: TPopularDestination, index: number) => (
                <DestinationCard destination={destination} key={index} />
              )
            )
          : null}
      </div>
    </section>
  );
};
export default PopularDestination;
