import { GStarRating } from '../../g-star-rating';
import { useQuery } from '@apollo/client';
import { CountData } from '@/Lib/types/count-data';
import { ConfigQuery } from '@/Lib/graphql/queries/config.query';

import Styles from './reviews-section.module.css';

interface ReviewsSectionProps {
  alternative?: boolean;
}

function ReviewsSection({
  alternative = false,
}: ReviewsSectionProps): JSX.Element {
  const { data } = useQuery<{ config: CountData }>(ConfigQuery);
  const reviewsCount = data?.config.reviews.count?.toLocaleString();
  const rating = data?.config.reviews.rating;

  return (
    <div
      className={
        alternative
          ? 'border-none'
          : 'mx-5 sm:mx-auto pt-5 pb-[1.125rem] md:pb-5 md:pt-6 text-center md:max-w-2xl border-b border-gray-400'
      }
    >
      <h3
        className={
          alternative
            ? 'mx-0 lg:mr-5 lg:ml-0 mb-3 text-center px-3 lg:pl-0 text-xl lg:text-3xl leading-8 font-bold lg:text-left leading-5 lg:leading-[1.625rem]'
            : 'text-xl font-bold mb-1 text-gray-800'
        }
      >
        The world&apos;s <span className={Styles.stroke}>best</span> work & travel trips.
      </h3>
      <div
        className={
          alternative
            ? 'space-x-2 text-gray-500 flex flex-row justify-center lg:justify-start items-center'
            : 'space-x-2 text-gray-500 flex flex-row justify-center items-center'
        }
      >
        <span>{rating}</span>
        <GStarRating
          translateY="-translate-y-px"
          rating={rating || 5}
          size={0.9}
        />
        <span>rating from {reviewsCount}+ reviews</span>
      </div>
    </div>
  );
}

export { ReviewsSection };
