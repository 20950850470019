import { gql } from '@apollo/client';

export const SEND_APP_LINK = gql`
  mutation SendAppLink($number: String!, $country: String!) {
    sendAppLink(phone: $number, country: $country) {
      code
      success
      message
    }
  }
`;
