import { appBannerTypes } from './types';

export const mockData: appBannerTypes = {
  image: 'new-website/static/homepage/app/banner.webp',
  title: 'Get the Global App',
  description:
    'Download our official app to find a trip or keep planning yours whilst on the go from the palm of your hand.',
  iosApp: {
    link: 'https://apps.apple.com/us/app/global-work-travel/id1535421676?itsct=apps_box_link&itscg=30200',
    image: 'new-website/static/homepage/app/app-store.svg',
  },
  androidApp: {
    link: 'https://play.google.com/store/apps/details?id=com.globalworkandtravel.gapp',
    image: 'new-website/static/homepage/app/google-play-badge.svg',
  },
  contact: {
    title: 'Text me a link',
    description:
      "Enter your number and we'll send you a free text with a link to download the app.",
    smsConfirmation: "Thanks! Check your phone, we've just messaged you.",
  },
};
