import { FC } from 'react';
import SearchTabs from '@/Components/search-panel/search-tabs';
import styles from '@/Components/search-panel/search-panel.module.css';
import WorkIcon from '@/Assets/icons/work';
import StudyIcon from '@/Assets/icons/study';
import TourIcon from '@/Assets/icons/tour';
import FlyIcon from '@/Assets/icons/fly';
import HolidayIcon from '@/Assets/icons/holiday';
import {
  CominSoonDesktopIcon,
  CominSoonMobileIcon,
} from '@/Assets/icons/coming-soon';

type props = {
  activeTab: string;
  onTabChange: (tab: string) => void;
};

const SearchPanelTabs: FC<props> = ({ activeTab, onTabChange }) => {
  return (
    <div className="grid grid-cols-5 w-full lg:max-w-[35rem] text-center  m-auto">
      <div
        role={'button'}
        className={`h-[4.375rem] md:w-[7rem] md:h-[6rem] leading-5 flex flex-col items-center justify-center ${
          activeTab === SearchTabs.WORK && styles.activeTab
        }`}
        onClick={() => onTabChange(SearchTabs.WORK)}
      >
        <div className="hidden md:block">
          <WorkIcon active={activeTab === SearchTabs.WORK} />
        </div>
        <div className="md:hidden">
          <WorkIcon
            width={32}
            height={32}
            active={activeTab === SearchTabs.WORK}
          />
        </div>
        <div className="">Work</div>
      </div>
      <div
        role={'button'}
        className={`h-[4.375rem]  md:w-[7rem] md:h-[6rem] leading-5 flex flex-col items-center justify-center ${
          activeTab === SearchTabs.STUDY && styles.activeTab
        }`}
        onClick={() => onTabChange(SearchTabs.STUDY)}
      >
        <div className="hidden md:block">
          <StudyIcon active={activeTab === SearchTabs.STUDY} />
        </div>
        <div className="md:hidden">
          <StudyIcon
            width={32}
            height={32}
            active={activeTab === SearchTabs.STUDY}
          />
        </div>
        <div className="">Study</div>
      </div>
      <div
        role={'button'}
        className={`h-[4.375rem] md:w-[7rem] md:h-[6rem] leading-5 flex flex-col items-center justify-center ${
          activeTab === SearchTabs.TOUR && styles.activeTab
        }`}
        onClick={() => onTabChange(SearchTabs.TOUR)}
      >
        <div className="hidden md:block">
          <TourIcon active={activeTab === SearchTabs.TOUR} />
        </div>
        <div className="md:hidden">
          <TourIcon
            width={32}
            height={32}
            active={activeTab === SearchTabs.TOUR}
          />
        </div>

        <div className="">Tour</div>
      </div>
      <div
        role={'button'}
        className={`h-[4.375rem] md:w-[7rem] md:h-[6rem] leading-5 flex flex-col items-center justify-center relative `}
      >
        <div className="hidden md:block">
          <FlyIcon />
        </div>
        <div className="md:hidden">
          <FlyIcon width={32} height={32} />
        </div>
        <div className=" text-[#C0C0C1]">Fly</div>
        <div className=" hidden md:block absolute top-2 opacity-80">
          <CominSoonDesktopIcon />
        </div>
        <div className="h-[4.375rem] md:hidden absolute top-1 opacity-80">
          <CominSoonMobileIcon />
        </div>
      </div>
      <div
        role={'button'}
        className={`h-[4.375rem] md:w-[7rem] md:h-[6rem] leading-5 flex flex-col items-center justify-center relative  `}
      >
        <div className="hidden md:block">
          <HolidayIcon />
        </div>
        <div className="md:hidden">
          <HolidayIcon width={32} height={32} />
        </div>
        <div className=" text-[#C0C0C1] ">Holiday</div>
        <div className=" hidden md:block absolute top-2 opacity-80">
          <CominSoonDesktopIcon />
        </div>
        <div className="md:hidden absolute top-1 opacity-80">
          <CominSoonMobileIcon />
        </div>
      </div>
    </div>
  );
};

export default SearchPanelTabs;
